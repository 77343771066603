<template>
  <div>
    <div class="backBg" v-show="loding">
      <div class="boepr">
        <van-loading color="#1989fa" vertical> 加载中 </van-loading>
      </div>
    </div>
    <div v-show="loderror">
      <van-empty description="服务器错误" image="network">
        <van-button round type="danger" class="bottom-button" @click="load"
          >重新加载</van-button
        >
      </van-empty>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loding",
  data() {
    return {
      loding: true, //加载成功
      loderror: false, //加载失败
    };
  },
  methods: {
    load() {
      this.$emit("load");
    },
  },
};
</script>

<style scoped>
.backBg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 105;
  width: 100%;
  height: 100vh;
  background: #fff;
}
.boepr {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>