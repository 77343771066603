<template>
  <div class="app-box">
      <!-- end 头部开始-->
      <div class="Header">
        <div class="HeaderLeft">
          <p class="text1">团队人数(人)</p>
          <p class="text2" v-if="listData.statistics">
            {{ listData.statistics.total }}
          </p>
        </div>
        <div class="HeaderRight">
          <p class="textoep1" @click="Timeshow = true">
            {{ thisTime }}月团队总业绩（元）<i class="iconfont icon-xiala1"></i>
          </p>
          <p class="fontSize" v-if="listData.statistics">
            {{ listData.statistics.month_performance }}
          </p>
          <p class="fontbottomSize" v-if="listData.statistics">
            上月团队总业绩{{ listData.statistics.last_month_performance }}元
          </p>
        </div>
      </div>
      <!-- end头部结束 -->

      <van-tabs
        v-model="active"
        title-active-color="#0E8AFFFF"
        sticky
        animated
        color="#fff"
        title-inactive-color="#000"
        @click="onClick"
      >
        <van-tab
          v-for="(jinde, index1) in tabList"
          :key="index1"
          :title="jinde.name"
          :name="jinde.id"
        >
          <div class="ulBox">
            <template v-if="listData.lists">
              <div class="textColor">
                <div
                  class="textColor1"
                  :class="{ active: page.is_personal == 2 }"
                  @click="personalClick"
                >
                  团队业绩
                </div>
                <div
                  class="textColor1"
                  :class="{ active: page.is_personal == 1 }"
                  @click="teamClick"
                >
                  个人业绩
                </div>
                <div
                  class="textColor1"
                  :class="{ active: page.is_personal == '' }"
                  @click="allClick"
                >
                  全部
                </div>
              </div>
              <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list
                  v-model="loading"
                  :finished="finished"
                  finished-text="没有更多了"
                  :immediate-check="false"
                  @load="onLoad"
                >
                  <div
                    class="bacg"
                    v-for="(item, index) in listData.lists.data"
                    :key="index"
                  >
                    <div class="li">
                      <!-- end -->
                      <div class="moelUle">
                        <div class="left">
                          <img
                            v-if="item.avatar_img"
                            :src="item.avatar_img"
                            alt=""
                          />
                          <img
                            v-else
                            src="../../../src/assets/img/csUser.jpg"
                            alt=""
                          />
                          <div class="rightTtex">
                            <p class="listHeight">
                              <span class="nimcUser">{{
                                item.nickname == null ? "无" : item.nickname
                              }}</span>
                            </p>
                            <p class="listHeight">
                              {{ item.mobile == null ? "暂无" : item.mobile }}
                            </p>
                          </div>
                        </div>
                        <div class="right">
                          <p class="commissionFont">{{ item.role_name }}</p>
                          <p class="commissionFont">
                            佣金比例:{{
                              item.share_ratio == null ? "0" : item.share_ratio
                            }}%
                          </p>
                        </div>
                      </div>
                      <div class="bottomFot">
                        <p class="doCCc doCCc1">
                          团队业绩:{{ item.team_performance== null?'0':item.team_performance }}元
                        </p>
                        <p class="rightCCc">
                          个人业绩{{ item.personal_performance == null?'0':item.personal_performance }}元
                        </p>
                      </div>
                    </div>
                    <!-- end-Bottom -->
                    <div class="bottomFot bottomFot1">
                      <div class="doCCc">
                        <p class="colorCol">团队人数:{{ item.team_total }}人</p>
                        <p>
                          {{
                            item.joining_date == null
                              ? item.update_date
                              : item.joining_date
                          }}
                        </p>
                      </div>
                      <div class="rightCCc">
                        <van-button
                          type="primary"
                          size="small"
                          color="#EEF7FFFF"
                          v-if="item.is_operate == 1"
                          @click="Jump(item.id)"
                          >操作</van-button
                        >
                      </div>
                    </div>
                  </div>
                </van-list>
              </van-pull-refresh>
            </template>
          </div>
        </van-tab>
      </van-tabs>
      <!-- end弹出 -->
      <div>
        <van-popup v-model="Timeshow" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="year-month"
            title="选择年月"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="formatterClick"
            @cancel="Timeshow = false"
          />
        </van-popup>
      </div>
      <Loding ref="loding" @load="load"></Loding>
  </div>
</template>

<script>
import moment from "moment";
import { memberLists } from "@/request/api";
import { Notify } from "vant";
import Loding from "@/components/Loding/Loding"
export default {
  components:{
    Loding
  },
  data() {
    return {
      loading: true,
      active: 0,
      Timeshow: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      listData: [],
      tabList: [
        { name: "全部", id: "" },
        { name: "总监", id: "4" },
        { name: "经理", id: "5" },
      ],
      thisTime: moment(new Date()).format("MM"),
      page: {
        page: "1",
        size: "5",
        month: moment(new Date()).format("YYYY-MM"),
        role: "",
        is_personal: "",
      },
      refreshing: false, //下拉刷新
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.getData();
    
  },
  methods: {
    load(){
      this.$refs.loding.loding = true
      this.$refs.loding.loderror = false
      this.getData();
    },
    getData() {
      memberLists(this.page).then((res) => {
        if (res.code != 1000) {
          return;
        }
        this.listData = res.data;
        // 赋值tab切换个数
        this.tabList[1].name = "总监" + `(${res.data.statistics.director_num})`; //
        this.tabList[2].name = "经理" + `(${res.data.statistics.manager_num})`;
        this.$refs.loding.loding = false
      }).catch(error=>{
        this.$refs.loding.loderror = true
        this.$refs.loding.loding = false
      })
    },
    formatterClick(value) {
      this.page.page = "1"; //重置页数
      this.finished = false; //重置上拉加
      this.loading = false;
      let now = moment(value).format("YYYY-MM");
      this.page.month = now;
      // console.log(this.page);
      this.thisTime = moment(value).format("MM");
      this.getData2();
      this.Timeshow = false;
      return;
    },
    getData2() {
      memberLists(this.page).then((res) => {
        if (res.code != 1000) {
          return;
        }
        this.listData = res.data;
        // 赋值tab切换个数
        this.tabList[1].name = "总监" + `(${res.data.statistics.director_num})`; //
        this.tabList[2].name = "经理" + `(${res.data.statistics.manager_num})`;
      });
    },
    // tab切换
    onClick(name, title) {
      this.page.role = name;
      this.finished = false; //重置上拉加载
      this.loading = false;
      this.page.page = "1"; //重置页数
      this.getData();
    },
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.finished = false; //重置上拉加载
        this.page.page = "1"; //重置页数
        this.getData1();
        this.refreshing = false;
      }, 1000);
    },
    getData1() {
      memberLists(this.page).then((res) => {
        if (res.code != 1000) {
          return;
        }
        this.listData = res.data;
        // 赋值tab切换个数
        this.tabList[1].name = "总监" + `(${res.data.statistics.director_num})`; //
        this.tabList[2].name = "经理" + `(${res.data.statistics.manager_num})`;
        Notify({ type: "success", message: "刷新成功" });
      });
    },
    //上拉加载
    onLoad() {
      setTimeout(() => {
        this.getNextpage();
      }, 1000);
    },
    // ++
    getNextpage() {
      this.page.page++;
      memberLists(this.page).then((res) => {
        if (res.code != 1000) {
          return;
        }
        if (res.data.lists.data.length == 0) {
          this.finished = true; //没有了关闭
          // console.log(res.data.lists.data.length);
          return;
        }
        this.listData.lists.data = this.listData.lists.data.concat(
          res.data.lists.data
        );
        // 赋值tab切换个数
        this.tabList[1].name = "总监" + `(${res.data.statistics.director_num})`; //
        this.tabList[2].name = "经理" + `(${res.data.statistics.manager_num})`;
        this.loading = false;
      });
    },
    Jump(id) {
      this.$router.push({ path: "/Operation", query: { id: id } });
    },
    personalClick(e) {
      this.finished = false; //重置上拉加载
      this.loading = false;
      this.page.page = "1"; //重置页数
      this.page.is_personal = 2;
      this.getData();
    },
    teamClick(e) {
      this.finished = false; //重置上拉加载
      this.loading = false;
      this.page.page = "1"; //重置页数
      this.page.is_personal = 1;
      this.getData();
    },
    allClick() {
      this.finished = false; //重置上拉加载
      this.loading = false;
      this.page.page = "1"; //重置页数
      this.page.is_personal = "";
      this.getData();
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #efeff4;
  box-sizing: border-box;
}
::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #d0d2daff;
}
.ulBox .bacg {
  background: #fff;
  padding: 10px 0;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.ulBox .li {
  width: 95%;
  margin: auto;
  overflow: hidden;
  border-bottom: 1px solid #f3f3f5ff;
  color: #000A33FF;
}
.ulBox .li .pushIcon {
  color: #0e8affff;
  vertical-align: middle;
  font-size: 15px;
}
.textColor {
  overflow: hidden;
  background: #fff;
  padding: 5px 10px;
  box-sizing: border-box;
}
.textColor1 {
  color: #0e8affff;
  background: #0e8aff12;
  padding: 2px 4px;
  border-radius: 5px;
  float: right;
  font-size: 16px;
}
.moelUle {
  display: flex;
}
.moelUle .left {
  flex: 3;
  overflow: hidden;
}
.moelUle .left img {
  width: 50px;
  height: 50px;
  float: left;
  border-radius: 50%;
}
.moelUle .right {
  flex: 2;
  text-align: right;
  margin-top: 8px;
}
.rightTtex {
  font-size: 14px;
  margin-left: 55px;
}
.rightTtex .nimcUser {
  font-size: 14px;
  font-weight: bold;
}
.moelUle .right .colorjg {
  color: #db2600ff;
  line-height: 22px;
}
.bottomFot {
  width: 100%;
  margin: auto;
  padding-top: 10px;
  overflow: hidden;
  display: flex;
  color: #000A33FF;
}
.doCCc {
  color: #999dadff;
  font-size: 15px;
  flex: 4;
  line-height: 30px;
}
.doCCc1 {
  color: #000A33FF;
}
.rightCCc {
  font-size: 15px;
  flex: 3;
  text-align: right;
  line-height: 30px;
}
.textColor1 {
  margin-left: .4rem;
}
.rightCCc ::v-deep.van-button {
  color: #0e8affff !important;
}
.Header {
  background: url(../../assets/img/head_bg.jpg);
  background-size: 100% 100%;
  display: flex;
  padding: 26px 10px;
  box-sizing: border-box;
}
.Header .HeaderLeft {
  flex: 2;
  color: #fff;
}
.Header .HeaderRight {
  flex: 3;
  color: #fff;
  text-align: right;
}
.HeaderLeft .text1 {
  font-size: 15px;
}
.text2 {
  font-size: 35px;
}
.HeaderRight .textoep1 {
  font-size: 15px;
}
.HeaderRight .fontSize {
  font-size: 30px;
}
.HeaderRight .fontbottomSize {
  font-size: 12px;
  margin-top: 15px;
}
.commissionFont {
  font-size: 0.38rem;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
.textColor .active {
  border: 1px solid #0e8affff;
}
.colorCol {
  color: #000A33FF;
}
.bottomFot1{
  width: 95%;
}
.listHeight{
  line-height: .6rem;
}
</style>